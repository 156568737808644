








































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ImportFromExcelResponse } from '@/types/course.type';
import { TableHeader } from '@/types/general.type';

@Component
export default class FileCoursesDetailsComponent extends Vue {
  @Prop(Object) readonly data!: ImportFromExcelResponse;

  headerClass = 'grey darken-4 secondary--text pa-3 qb-table__header';

  get headers(): TableHeader[] {
    return [
      {
        text: this.$t('tableHeaders.courseName'),
        align: 'center',
        value: 'courseName',
        sortable: false,
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.studyPlanId'),
        value: 'studyPlanId',
        align: 'center',
        sortable: false,
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.studyPlan'),
        value: 'studyPlan',
        align: 'center',
        sortable: false,
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.courseCode'),
        align: 'center',
        value: 'courseCode',
        sortable: false,
        class: this.headerClass,
      },
    ];
  }

  limitOption = [5, 10, 20, 50];

  get footerOptions() {
    return {
      'items-per-page-text': this.$t('RowsPerPage'),
      'items-per-page-all-text': this.$t('ALL'),
      'items-per-page-options': this.limitOption,
    };
  }
}
