var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-8"},[_c('div',{staticClass:"d-flex justify-center mb-3"},[_c('h6',{staticClass:"pb-2 px-1 bordered-b title-font-size"},[_vm._v(" "+_vm._s(_vm.$t("titles.addedCourses"))+" ( "+_vm._s(_vm.data.addedCourses.length)+" ) ")])]),_c('v-data-table',{staticClass:" qb-table",attrs:{"items":_vm.data.addedCourses,"headers":_vm.headers,"items-per-page":5,"no-data-text":_vm.$t('labels.noData'),"footer-props":_vm.footerOptions},scopedSlots:_vm._u([{key:"item.courseName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("servertranslate")(item.crs_name)))]}},{key:"item.studyPlanId",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.study_plan_id))]}},{key:"item.studyPlan",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("servertranslate")(item.study_plan_name)))]}},{key:"item.courseCode",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.courseCode || " - "))]}},{key:"footer.page-text",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
var itemsLength = ref.itemsLength;
return [_c('div',[_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" "+_vm._s(_vm.$t("Of"))+" "+_vm._s(itemsLength)+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }